<template>
  <div class="home">
    <div class="home__title"> I AM BORED</div>
    <div class="home__activity" id="activity"> {{ activity }}</div>
    <button class="home__actions" @click="fetchActivities">Not Good Enough</button>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default {
  name: "Home",
  components: {
  },
  data() {
    return {
      activity: ''
    }
  },
  created() {
    this.fetchActivities()
  },
  methods: {
    async fetchActivities() {
      await axios.get('https://www.boredapi.com/api/activity').then(res => {
        const response = res.data
        this.activity = response.activity
      })

      const activityBox = document.getElementById('activity')
      if(!activityBox.classList.contains('fadein')) {
        activityBox.classList.add('fadein')

        setTimeout(() => {
          activityBox.classList.remove('fadein')
        }, 1000)

      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to left bottom, #140e30, #181236, #1c163b, #211a41, #251e47);
  &__title {
    font-size: 72px;
    color: whitesmoke;
    text-align: center;
    margin-bottom: 150px;
  }
  &__activity {
    padding: 30px 60px;
    text-align: center;
    border: 1px solid whitesmoke;
    margin: 20px;
    font-size: 36px;
    border-radius: 16px;
    color: whitesmoke;
  }
  &__actions {
    padding: 10px 30px;
    width: 250px;
    height: 80px;
    border-radius: 16px;
    background-color: #3cbdc2;
    color: whitesmoke;
    margin: 40px;
    text-align: center;
    white-space: nowrap;
    font-size: 24px;
    cursor: pointer;
    border: none;
    outline: none;
  }
}

.fadein{
  animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
</style>